import React from "react";
import {SetValues, ValueErrors, Values} from "../hooks/useRegistration";
import Email from "./fields/Email";
import Password from "./fields/Password";
import FirstName from "./fields/FirstName";
import LastName from "./fields/LastName";
import CompanyName from "./fields/CompanyName";
import JobTitle from "./fields/JobTitle";
import Phone from "./fields/Phone";
import JobTitleLevel from "./fields/JobTitleLevel";
import FunctionalArea from "./fields/FunctionalArea";
import CompanySize from "./fields/CompanySize";
import Industry from "./fields/Industry";
import Country from "./fields/Country";
import State from "./fields/State";
import City from "./fields/City";
import Street from "./fields/Street";
import PostalCode from "./fields/PostalCode";
import {log} from "../../utility/debug";
import Department from "./fields/Department";

/**
 * @interface Props
 */
interface Props {
    fields: string[];
    values: Values;
    setValues: SetValues;
    errors: ValueErrors;
}

/**
 * Component for handling display of required user fields as part of user registration/update.
 *
 * @param fields
 * @param values
 * @param setValues
 * @param errors
 * @constructor
 */
function Fields({fields, values, setValues, errors}: Props) {
    const formFields = fields.map((field) => {
        switch (field) {
            case "email":
                return <Email field={field} value={values.email} setValue={setValues.setEmail} errors={errors.email} />;
            case "password":
                return <Password field={field} value={values.password} setValue={setValues.setPassword} errors={errors.password} />;
            case "firstName":
                return <FirstName field={field} value={values.firstName} setValue={setValues.setFirstName} errors={errors.firstName} />;
            case "lastName":
                return <LastName field={field} value={values.lastName} setValue={setValues.setLastName} errors={errors.lastName} />;
            case "companyName":
                return <CompanyName field={field} value={values.companyName} setValue={setValues.setCompanyName} errors={errors.companyName} />;
            case "jobTitle":
                return <JobTitle field={field} value={values.jobTitle} setValue={setValues.setJobTitle} errors={errors.jobTitle} />;
            case "phone":
                return <Phone field={field} value={values.phone} setValue={setValues.setPhone} errors={errors.phone} />;
            case "jobTitleLevel":
                return <JobTitleLevel field={field} value={values.jobTitleLevel} setValue={setValues.setJobTitleLevel} errors={errors.jobTitleLevel} />;
            case "functionalArea":
                return <FunctionalArea field={field} value={values.functionalArea} setValue={setValues.setFunctionalArea} errors={errors.functionalArea} />;
            case "companySize":
                return <CompanySize field={field} value={values.companySize} setValue={setValues.setCompanySize} errors={errors.companySize} />;
            case "industry":
                return <Industry field={field} value={values.industry} setValue={setValues.setIndustry} errors={errors.industry} />;
            case "country":
                return <Country field={field} value={values.country} setValue={setValues.setCountry} errors={errors.country} />;
            case "state":
                return <State field={field} value={values.state} setValue={setValues.setState} country={values.country} errors={errors.state} />;
            case "city":
                return <City field={field} value={values.city} setValue={setValues.setCity} errors={errors.city} />;
            case "street":
                return <Street field={field} value={values.street} setValue={setValues.setStreet} errors={errors.street} />;
            case "postalCode":
                return <PostalCode field={field} value={values.postalCode} setValue={setValues.setPostalCode} errors={errors.postalCode} />;
            case "department":
                return <Department field="department" value={values.department} setValue={setValues.setDepartment} errors={errors.department} />;
            default:
                log(`Unrecognised Field ${field}`);
                return <></>;
        }
    });
    return <>{formFields}</>;
}

export default Fields;
