import React from "react";
import Logout from "../forms/buttons/Logout";
import {useUser} from "../providers/UserProvider";

/**
 * Component for Welcome Back block.
 *
 * @constructor
 */
function WelcomeBack(): JSX.Element {
    const {user} = useUser();
    return <>
        <div className="wb-wrap">
            Welcome back, {user.fullName}!
        </div>
        <div className="not-you-wrap">
            <Logout className="not-you">Not you?</Logout>
        </div>
    </>;
}

export default WelcomeBack;
