import React, {FormEvent, useState} from "react";
import useRegistration, {SetValueErrors, ValueErrors} from "../hooks/useRegistration";
import Fields from "./Fields";
import Loader from "../blocks/Loader";
import {Alert, Form} from "react-bootstrap";
import Login from "./buttons/Login";
import {createUser, Status, updateUser} from "../../api/api";
import {useUser} from "../providers/UserProvider";
import {UserActionType} from "../providers/UserProvider.types";

/**
 * @interface Props
 */
interface Props {
    fields: string[];
    userUpdated: () => void;
    children?: any;
    loginButton?: JSX.Element;
}

/**
 * @interface Errors
 */
interface Errors extends ValueErrors {
    alert?: string
}

/**
 * Component which handles user registration/update.
 *
 * @param fields
 * @param userUpdated
 * @param loginButton
 * @param children
 * @constructor
 */
function Registration({fields, userUpdated, loginButton, children}: Props): JSX.Element {
    const {values, setValues, save, isLoading} = useRegistration();
    const {user, isAuthenticated, dispatch, accessToken} = useUser();
    const [apiStatus, setApiStatus] = useState(Status.NotSent);
    const [errors, setErrors]: [Errors, SetValueErrors] = useState({});

    if (isLoading) {
        return <Loader />;
    }

    if (apiStatus === Status.Fetching) {
        return <Loader expectedWaitTimeInSeconds={30} />;
    } else if (apiStatus === Status.Success && !isAuthenticated) {
        return <>
            <Alert variant="success">
                {user.fullName}, your account has been successfully created! Verification email has been sent
                to {user.email}. Please, log in to continue.
            </Alert>
            {loginButton ? loginButton: <Login className="btn-register" />}
        </>;
    }

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!fields.length) {
            // User already has all required fields filled in.
            // Skipping user update and going straight to download/registration.
            userUpdated();
            return;
        }

        save();
        setApiStatus(Status.Fetching);
        const [response, errors] = isAuthenticated ?
            await updateUser(user.authID, accessToken, values) :
            await createUser(values);
        if (!errors) {
            dispatch({
                type: isAuthenticated ? UserActionType.UpdateUser : UserActionType.CreateUser,
                user: response
            });
            setApiStatus(Status.Success);
            if (isAuthenticated) {
                userUpdated();
            }
        } else {
            setErrors(errors);
            setApiStatus(Status.Error);
        }
    }
    const errorMsg = Status.Error && !!errors.alert ? (
        <Alert variant="danger">
            <div>{errors.alert}</div>
            <div>Please, contact <a href="mailto:support@cyberriskalliance.com">support@cyberriskalliance.com</a>.</div>
        </Alert>
    ) : <></>;
    return (
        <Form className="w-100" onSubmit={onSubmit}>
            {errorMsg}
            <Fields fields={fields} values={values} setValues={setValues} errors={errors} />
            {children}
        </Form>
    );
}

export default Registration;
