import React from "react";
import SelectInput from "./SelectInput";
import countries from "country-list";

/**
 * @interface Props
 */
interface Props {
    field: string;
    value: string;
    setValue: (value: string) => void;
    errors?: string[];
}

const options = [
    {key: "US", value: String(countries.getName("US"))},
    {key: "CA", value: String(countries.getName("CA"))},
    {key: "GB", value: String(countries.getName("GB"))},
].concat(
    countries.getData()
        .filter(country => !["US", "GB", "CA"].includes(country.code))
        .sort((a, b) => {
            if (a.name === b.name) {
                return 0;
            }
            return a.name < b.name ? -1 : 1;
        })
        .map(country => ({key: country.code, value: country.name}))
);

/**
 * Country field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function Country({field, value, setValue, errors}: Props): JSX.Element {
    return <SelectInput name={field} label="Country" options={options} value={value} setValue={setValue} errors={errors} />;
}

export default Country;
