import React from "react";
import {Spinner} from "react-bootstrap";

/**
 * Redirect component.
 *
 * Future proof in case it should be different from Loader.
 *
 * @constructor
 */
function Redirect(): JSX.Element {
    return (
        <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Redirecting...</span>
        </Spinner>
    );
}

export default Redirect;
