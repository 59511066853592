import React, {createContext, useContext, useEffect, useReducer} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {getUser} from "../../api/api";
import {
    Action,
    LoadUserFromApiParams,
    LoadUserFromAuth0Params,
    LoadUserFromStorage,
    Props,
    SaveUserToStorage,
    State,
    User,
    UserActionType,
    UserContextType,
    UserDispatch
} from "./UserProvider.types";
import {genericError, log} from "../../utility/debug";


/**
 * Returns an anonymous user object.
 *
 * @return User
 */
const getAnonymousUser = (): User => {
    return {
        authID: "",
        email: "",
        emailVerified: false,
        updatedAt: "",
        fullName: "",
        omedaCustomerEncryptedID: "",
    }
}

/**
 * Context for UserProvider.
 */
const UserStateContext = createContext<UserContextType | undefined>(undefined);

/**
 * Reducer for UserProvider state.
 *
 * @param state State
 * @param action Action
 *
 * @return State
 */
function userReducer(state: State, action: Action): State {
    switch (action.type) {
        case UserActionType.SavedToStorage:
            return {
                ...state,
                shouldSave: false
            };
        case UserActionType.Logout:
            return {
                ...state,
                user: getAnonymousUser(),
                accessToken: "",
                isAuthenticated: false,
                shouldSave: true,
                hasLoadedFromStorage: true,
                hasLoadedFromAuth0: true,
                shouldLoadFromApi: false
            };
        case UserActionType.LoadFromStorage:
            return {
                ...state,
                user: {...state.user, ...action.user},
                hasLoadedFromStorage: true
            };
        case UserActionType.LoadFromAuth0:
            const isFullyLoaded = !!state.user.fullName && !!state.user.omedaCustomerEncryptedID;
            return action.isAuthenticated ?
                {
                    ...state,
                    user: {
                        ...state.user,
                        authID: action.user.authID,
                        email: action.user.email,
                        emailVerified: action.user.emailVerified
                    },
                    accessToken: action.accessToken || "",
                    isAuthenticated: true,
                    hasLoadedFromAuth0: true,
                    shouldLoadFromApi: !isFullyLoaded
                } :
                {
                    ...state,
                    isAuthenticated: false,
                    hasLoadedFromAuth0: true
                };
        case UserActionType.LoadFromApi:
            return {
                ...state,
                user: {
                    ...state.user,
                    fullName: action.user.fullName,
                    omedaCustomerEncryptedID: action.user.omedaCustomerEncryptedID,
                    updatedAt: action.user.updatedAt
                },
                shouldSave: true,
                shouldLoadFromApi: false
            }
        case UserActionType.ErrorOccurred:
            return {
                ...state,
                error: action.error,
                hasLoadedFromStorage: true,
                hasLoadedFromAuth0: true,
                shouldLoadFromApi: false,
            };
        case UserActionType.CreateUser:
        case UserActionType.UpdateUser:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.user.email,
                    fullName: action.user.fullName,
                    omedaCustomerEncryptedID: action.user.omedaCustomerEncryptedID,
                    updatedAt: action.user.updatedAt,
                }
            };
        case UserActionType.TokenExpired:
            return {...state, hasAccessTokenExpired: true};
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

/**
 * Provider component for user/auth related functionality.
 *
 * @param children
 * @constructor
 */
export function UserProvider({children}: Props): JSX.Element {
    const [state, dispatch]: [State, UserDispatch] = useReducer(userReducer, {
        user: getAnonymousUser(),
        isAuthenticated: false,
        shouldSave: false,
        hasLoadedFromStorage: false,
        hasLoadedFromAuth0: false,
        shouldLoadFromApi: false,
        accessToken: "",
        hasAccessTokenExpired: false
    });
    const {isLoading, isAuthenticated, error, user, logout, getIdTokenClaims} = useAuth0();

    useEffect(() => {
        loadUserFromStorage({hasLoadedFromStorage: state.hasLoadedFromStorage, dispatch});
    }, [state.hasLoadedFromStorage]);

    useEffect(() => {
        loadUserFromAuth0({
            isLoading,
            isAuthenticated,
            hasLoadedFromAuth0: state.hasLoadedFromAuth0,
            user,
            dispatch,
            getIdTokenClaims
        });
    }, [isLoading, isAuthenticated, state.hasLoadedFromAuth0, user, getIdTokenClaims]);

    useEffect(() => {
        loadUserFromApi({shouldLoadFromApi: state.shouldLoadFromApi, user, token: state.accessToken, dispatch});
    }, [state.accessToken, state.shouldLoadFromApi, user]);

    useEffect(() => {
        saveUserToStorage({shouldSave: state.shouldSave, user: state.user, dispatch});
    }, [state.shouldSave, state.user]);

    const value = {
        user: state.user,
        isLoading: !state.hasLoadedFromStorage || !state.hasLoadedFromAuth0 || state.shouldLoadFromApi || isLoading,
        isAuthenticated: state.isAuthenticated,
        error: state.error || error,
        dispatch,
        logout: () => {
            dispatch({type: UserActionType.Logout});
            logout({returnTo: process.env.SSO_AUTH0_REDIRECT_URI});
        },
        accessToken: state.accessToken,
        hasAccessTokenExpired: state.hasAccessTokenExpired
    };
    return <UserStateContext.Provider value={value}>{children}</UserStateContext.Provider>;
}

/**
 * The main function to be used by consumers. A syntax sugar on top of useContext for UserProvider.
 */
export function useUser() {
    const context = useContext(UserStateContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
}

/**
 * Loads basic user data from Local Storage.
 *
 * @param hasLoadedFromStorage boolean
 * @param dispatch UserDispatch
 */
function loadUserFromStorage({hasLoadedFromStorage, dispatch}: LoadUserFromStorage) {
    if (!hasLoadedFromStorage) {
        const {fullName, omedaCustomerEncryptedID, updatedAt} = JSON.parse(localStorage.getItem("ssoUser") || "{}");
        dispatch({
            type: UserActionType.LoadFromStorage,
            user: {
                fullName: fullName || "",
                omedaCustomerEncryptedID: omedaCustomerEncryptedID || "",
                updatedAt: updatedAt || "",
            }
        });
    }
}

/**
 * Loads user auth data from Auth0.
 *
 * @param isLoading
 * @param isAuthenticated
 * @param hasLoadedFromAuth0
 * @param user
 * @param dispatch
 * @param getIdTokenClaims
 */
async function loadUserFromAuth0({
                                     isLoading,
                                     isAuthenticated,
                                     hasLoadedFromAuth0,
                                     user,
                                     dispatch,
                                     getIdTokenClaims
                                 }: LoadUserFromAuth0Params) {
    if (!hasLoadedFromAuth0 && !isLoading) {
        if (isAuthenticated) {
            localStorage.setItem("ssoRegistrationValues", "{}");
            try {
                const token = await getIdTokenClaims();
                dispatch({
                    type: UserActionType.LoadFromAuth0,
                    user: {
                        authID: user.sub,
                        email: user.email,
                        emailVerified: user.email_verified
                    },
                    accessToken: token.__raw,
                    isAuthenticated: true
                });
                const tokenExpiresIn = Number(token.exp) * 1000 - Date.now();
                if (tokenExpiresIn > 0) {
                    setTimeout(() => dispatch({type: UserActionType.TokenExpired}), tokenExpiresIn);
                } else {
                    dispatch({
                        type: UserActionType.ErrorOccurred,
                        error: new Error(`Token expiration is incorrect: ${token.exp}`)
                    });
                }
            } catch (error) {
                log(error);
                dispatch({
                    type: UserActionType.ErrorOccurred,
                    error: genericError(),
                });
            }
        } else {
            dispatch({
                type: UserActionType.LoadFromAuth0,
                isAuthenticated: false
            });
        }
    }
}

/**
 * Loads basic user data from User API.
 *
 * @param shouldLoadFromApi
 * @param user
 * @param token
 * @param dispatch
 */
async function loadUserFromApi({shouldLoadFromApi, user, token, dispatch}: LoadUserFromApiParams) {
    if (shouldLoadFromApi) {
        const [data, error] = await getUser(user.sub, token);
        if (!error) {
            const {fullName, omedaCustomerEncryptedID, updatedAt} = data;
            dispatch({
                type: UserActionType.LoadFromApi,
                user: {
                    fullName,
                    omedaCustomerEncryptedID,
                    updatedAt,
                }
            });
        } else {
            dispatch({
                type: UserActionType.ErrorOccurred,
                error
            });
        }
    }
}

/**
 * Saves basic user data to Local Storage.
 *
 * @param shouldSave
 * @param user
 * @param dispatch
 */
function saveUserToStorage({shouldSave, user, dispatch}: SaveUserToStorage) {
    if (shouldSave) {
        localStorage.setItem("ssoUser", JSON.stringify({
            fullName: user.fullName,
            omedaCustomerEncryptedID: user.omedaCustomerEncryptedID,
            updatedAt: user.updatedAt,
        }));
        dispatch({type: UserActionType.SavedToStorage});
    }
}
