import React from "react";
import SelectInput from "./SelectInput";
import TextInput from "./TextInput";

const UsaStates = require('usa-states').UsaStates;

/**
 * @interface Props
 */
interface Props {
    field: string;
    value: string;
    setValue: (value: string) => void;
    country: string;
    errors?: string[];
}

/**
 * @interface StateType
 */
interface StateType {
    abbreviation: string,
    name: string
}

const usStates = new UsaStates();
const options = usStates.states.map((state: StateType) => ({key: state.abbreviation, value: state.name}));

/**
 * State field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param country
 * @param errors
 * @constructor
 */
function State({field, value, setValue, country, errors}: Props): JSX.Element {
    if (!country) {
        return <></>;
    } else if (country === "US") {
        return <SelectInput name={field} label="State" options={options} value={value} setValue={setValue} errors={errors} />;
    } else {
        return <TextInput name={field} type="text" label="State" value={value} setValue={setValue} errors={errors} />;
    }
}

export default State;
