import React from "react";
import {Form} from "react-bootstrap";

/**
 * @interface Props
 */
interface Props {
    name: string,
    type: string,
    label: string,
    value: string,
    setValue: (value: string) => void,
    errors?: string[]
}

/**
 * General text input component.
 *
 * @param name
 * @param type
 * @param label
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function TextInput({name, type, label, value, setValue, errors}: Props): JSX.Element {
    const _errors = errors || [];
    return (
        <Form.Group controlId={name}>
            <Form.Label className="sr-only">{label}</Form.Label>
            <Form.Control name={name} type={type} placeholder={label} required={true} value={value} onChange={e => setValue(e.target.value)} isInvalid={!!_errors.length} />
            {_errors.map(error => <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>)}
        </Form.Group>
    );
}

export default TextInput;
