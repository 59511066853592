import {GetIdTokenClaimsOptions, IdToken} from "@auth0/auth0-spa-js";

/**
 * UserProvider Props
 *
 * @interface Props
 */
export interface Props {
    children: any
}

/**
 * @interface User
 */
export interface User {
    authID: string,
    email: string,
    emailVerified: boolean,
    updatedAt: string,
    fullName: string,
    omedaCustomerEncryptedID: string,
}

/**
 * @interface State
 */
export interface State {
    user: User,
    isAuthenticated: boolean,
    shouldSave: boolean,
    hasLoadedFromStorage: boolean,
    hasLoadedFromAuth0: boolean,
    shouldLoadFromApi: boolean,
    accessToken: string,
    hasAccessTokenExpired: boolean,
    error?: Error,
}

/**
 * @interface UserContextType
 */
export interface UserContextType {
    user: User,
    dispatch: UserDispatch,
    isLoading: boolean,
    isAuthenticated: boolean,
    logout: () => void,
    accessToken: string,
    hasAccessTokenExpired: boolean,
    error?: Error
}

/**
 * @type UserDispatch
 */
export type UserDispatch = (action: Action) => void;

/**
 * @interface Action
 */
export interface Action {
    type: UserActionType,
    user?: any,
    isAuthenticated?: boolean,
    isLoading?: boolean,
    accessToken?: string,
    error?: Error
}

/**
 * @enum UserActionType
 */
export enum UserActionType {
    CreateUser = "CreateUser",
    UpdateUser = "UpdateUser",
    Logout = "Logout",
    LoadFromStorage = "LoadFromStorage",
    LoadFromAuth0 = "LoadFromAuth0",
    LoadFromApi = "LoadFromApi",
    SavedToStorage = "SavedToStorage",
    ErrorOccurred = "ErrorOccurred",
    TokenExpired = "TokenExpired"
}

/**
 * @interface LoadUserFromStorage
 */
export interface LoadUserFromStorage {
    hasLoadedFromStorage: boolean,
    dispatch: UserDispatch,
}

/**
 * @interface LoadUserFromAuth0Params
 */
export interface LoadUserFromAuth0Params {
    isLoading: boolean,
    isAuthenticated: boolean,
    hasLoadedFromAuth0: boolean,
    user: any,
    dispatch: UserDispatch,
    getIdTokenClaims: (options?: GetIdTokenClaimsOptions) => Promise<IdToken>
}

/**
 * @interface LoadUserFromApiParams
 */
export interface LoadUserFromApiParams {
    shouldLoadFromApi: boolean,
    user: any,
    token: string,
    dispatch: UserDispatch,
}

/**
 * @interface SaveUserToStorage
 */
export interface SaveUserToStorage {
    shouldSave: boolean,
    user: User,
    dispatch: UserDispatch,
}
