import React, {useEffect} from "react";
import ContinueReading from "./ContinueReading";
import $ from "jquery";
import {useUser} from "../providers/UserProvider";
import axios from "axios";
import {log} from "../../utility/debug";

/**
 * Component for handling gated content.
 *
 * @constructor
 */
function Gated(): JSX.Element {
    const {isAuthenticated} = useUser();
    useEffect(() => {
        if (isAuthenticated) {
            const postId = $('.post.is-gated').attr('data-id');
            axios.get(`${window.location.origin}/wp-json/wp/v2/posts/${postId}?context=view`)
                .then(response => {
                    $('.is-gated .post-content .wysiwyg .gated-content').replaceWith(response.data.content.rendered);
                })
                .catch(error => {
                    log(error);
                });
        }
    }, [isAuthenticated]);

    return isAuthenticated ? <></> : <ContinueReading />;
}

export default Gated;
