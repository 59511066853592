import React from "react";
import TextInput from "./TextInput";

/**
 * @interface Props
 */
interface Props {
    field: string;
    value: string;
    setValue: (value: string) => void;
    errors?: string[];
}

/**
 * City field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function City({field, value, setValue, errors}: Props): JSX.Element {
    return <TextInput name={field} type="text" label="City" value={value} setValue={setValue} errors={errors} />;
}

export default City;
