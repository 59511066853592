import React, {useEffect, useState} from "react";
import {ProgressBar, Spinner} from "react-bootstrap";

interface Props {
    expectedWaitTimeInSeconds?: number;
}

/**
 * Loader component.
 *
 * @constructor
 * @param expectedWaitTime
 */
function Loader({expectedWaitTimeInSeconds}: Props): JSX.Element {
    const initialCountdown = 1000 * (expectedWaitTimeInSeconds || 2);
    const [countdown, setCountdown] = useState(initialCountdown);
    const countdownStep = 100;

    useEffect(() => {
        if (countdown > 0) {
            setTimeout(() => setCountdown(countdown - countdownStep), countdownStep);
        }
    }, [countdown]);

    if (countdown <= 0) {
        return (
            <Spinner animation="border" role="status" variant="primary">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    } else {
        const now = Math.round((initialCountdown - countdown) / initialCountdown * 100);
        return <ProgressBar now={now} label={`${now}%`} srOnly />;
    }
}

export default Loader;
