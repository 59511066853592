import React from "react";
import SelectInput, {stringToOption} from "./SelectInput";

/**
 * @interface Props
 */
interface Props {
    field: string;
    value: string;
    setValue: (value: string) => void;
    errors?: string[];
}

/**
 * Company Size field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function CompanySize({field, value, setValue, errors}: Props): JSX.Element {
    const options = [
        '1 to 50',
        '51 to 200',
        '201 to 500',
        '501 to 1000',
        '1001 to 5000',
        '5001 to 10,000',
        '10,001+',
    ];
    return <SelectInput name={field} label="Company Size" options={options.map(stringToOption)} value={value} setValue={setValue} errors={errors} />;
}

export default CompanySize;
