import React from "react";
import {Form} from "react-bootstrap";

/**
 * @interface Props
 */
interface Props {
    name: string;
    label: string;
    options: Option[];
    value: string;
    setValue: (value: string) => void;
    errors?: string[];
}

/**
 * @interface Option
 */
export interface Option {
    key: string;
    value: string;
    disabled?: boolean;
}

/**
 * Converts string to Option.
 *
 * @param item string
 *
 * @return Option
 */
export const stringToOption = (item: string): Option => ({key: item, value: item, disabled: false});

/**
 * General select input component.
 *
 * @param name
 * @param label
 * @param options
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function SelectInput({name, label, options, value, setValue, errors}: Props): JSX.Element {
    const _options = [{key: '', value: label}, {key: '', value: '', disabled: true}].concat(options);
    const _errors = errors || [];
    return (
        <Form.Group controlId={name}>
            <Form.Label className="sr-only">{label}</Form.Label>
            <Form.Control as="select" required={true} value={value} onChange={e => setValue(e.target.value)}>
                {_options.map(item => <option value={item.key} disabled={!!item.disabled}>{item.value}</option>)}
            </Form.Control>
            {_errors.map(error => <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>)}
        </Form.Group>
    );
}

export default SelectInput;
