import '../sass/App.sass';

import React from 'react';
import {useCookies} from "react-cookie";
import ContentRegistration from "./blocks/ContentRegistration";
import $ from 'jquery';
import Gated from "./blocks/Gated";
import HeaderRegister from "./blocks/HeaderRegister";
import Loader from "./blocks/Loader";
import Redirect from "./blocks/Redirect";
import {useUser} from "./providers/UserProvider";
import GlobalError from "./blocks/GlobalError";
import {log} from "../utility/debug";
import {PostType} from "../api/api";
import ContentSubscription from "./blocks/ContentSubscription";

export const APP_NAME = "SsoFrontend";

/**
 * The main App component.
 *
 * @constructor
 *
 * @return JSX.Element
 */
function App(): JSX.Element {
    const {
        isLoading,
        error,
        user
    } = useUser();
    const [cookies, setCookie] = useCookies(['ssoRedirectTo']);

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        log(error.message);
        return <GlobalError />;
    }

    // After Auth0 login is complete (on /sso route) we can return back to the initial page.
    if (cookies.ssoRedirectTo && !!/^\/sso\/?$/.exec(window.location.pathname)) {
        // @ts-ignore
        if (user.omedaCustomerEncryptedID && window.olytics && window.olytics.confirm) {
            // @ts-ignore
            window.olytics.confirm(user.omedaCustomerEncryptedID);
        }
        // @ts-ignore
        if (user && user.email && window.humTracker) {
            // @ts-ignore
            window.humTracker.identify(user.email);
        }
        setCookie('ssoRedirectTo', '', {path: '/'});
        window.location.replace(cookies.ssoRedirectTo);
        return <Redirect />;
    }

    const $body = $('body');
    const postID = $body.find("article").attr("data-id") || "";
    if ($body.hasClass('sso-redirect')) {
        // React re-renders faster then actual redirect from /sso happens.
        // Need to make sure Redirect block is shown to the user throughout the whole process.
        return <Redirect />;
    } else if ($body.hasClass('category') && $body.hasClass('category-scawards')) {
        return <><ContentSubscription id="awards" /><HeaderRegister /></>;
    } else if ($body.hasClass('category') && ($body.hasClass('category-reviews'))) {
        return <><ContentSubscription id="labs" /><HeaderRegister /></>;
    } else if ($body.hasClass('single-hm-webcast')) {
        return <ContentRegistration postType={PostType.Webcast} postID={postID} />;
    } else if ($body.hasClass('single-cra_whitepaper')) {
        return <ContentRegistration postType={PostType.Whitepaper} postID={postID} />;
    } else if ($body.hasClass('single-post') && $('.post.type-post').hasClass('is-gated')) {
        return <><Gated /><HeaderRegister /></>;
    } else {
        return <HeaderRegister />;
    }
}

export default App;
