import React, {useState} from "react";
import Login from "../forms/buttons/Login";
import RegistrationModal from "./RegistrationModal";

/**
 * Component for Continue Reading block.
 * @constructor
 */
function ContinueReading(): JSX.Element {
    const [modal, setModal] = useState(false);
    return (
        <div id="continue-reading-block">
            <div className="action-wrapper">
                <h3 className="title">Please register to continue.</h3>
                <p className="highlighted">Already registered? Log in.</p>
                <div className="action-buttons">
                    <button className="button button-white" onClick={() => setModal(true)}>Create Free Account</button>
                    <Login className="button button-red" title="Log in to Existing Account" />
                </div>
            </div>
            <div className="benefits-wrapper">
                <h4>Once you register, you'll receive:</h4>
                <ul>
                    <li>
                        <h5>News analysis</h5>
                        <p>The context and insight you need to stay abreast of the most important developments in
                            cybersecurity. CISO and practitioner perspectives; strategy and tactics; solutions and
                            innovation; policy and regulation.</p>
                    </li>
                    <li>
                        <h5>Archives</h5>
                        <p>Unlimited access to nearly 20 years of SC Media industry analysis and
                            news-you-can-use.</p>
                    </li>
                    <li>
                        <h5>Daily Newswire</h5>
                        <p>SC Media’s essential morning briefing for cybersecurity professionals.</p>
                    </li>
                    <li>
                        <h5>Learning Express</h5>
                        <p>One-click access to our extensive program of virtual events, with convenient calendar
                            reminders and ability to earn CISSP credits.</p>
                    </li>
                </ul>
            </div>
            <RegistrationModal show={modal} setShow={setModal} />
        </div>
    );
}

export default ContinueReading;
