import {useEffect, useState} from "react";

/**
 * @interface UseRegistration
 */
export interface UseRegistration {
    values: Values,
    setValues: SetValues,
    save: () => void,
    isLoading: boolean
}

/**
 * @interface Values
 */
export interface Values {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
    jobTitle: string;
    phone: string;
    jobTitleLevel: string;
    functionalArea: string;
    companySize: string;
    industry: string;
    country: string;
    state: string;
    city: string;
    street: string;
    postalCode: string;
    department: string;
}

/**
 * @interface SetValues
 */
export interface SetValues {
    setEmail: (value: string) => void;
    setPassword: (value: string) => void;
    setFirstName: (value: string) => void;
    setLastName: (value: string) => void;
    setCompanyName: (value: string) => void;
    setJobTitle: (value: string) => void;
    setPhone: (value: string) => void;
    setJobTitleLevel: (value: string) => void;
    setFunctionalArea: (value: string) => void;
    setCompanySize: (value: string) => void;
    setIndustry: (value: string) => void;
    setCountry: (value: string) => void;
    setState: (value: string) => void;
    setCity: (value: string) => void;
    setStreet: (value: string) => void;
    setPostalCode: (value: string) => void;
    setDepartment: (value: string) => void;
}

/**
 * @interface ValueErrors
 */
export interface ValueErrors {
    alert?: string;
    email?: string[];
    password?: string[];
    firstName?: string[];
    lastName?: string[];
    companyName?: string[];
    jobTitle?: string[];
    phone?: string[];
    jobTitleLevel?: string[];
    functionalArea?: string[];
    companySize?: string[];
    industry?: string[];
    country?: string[];
    state?: string[];
    city?: string[];
    street?: string[];
    postalCode?: string[];
    department?: string[];
}

/**
 * @type SetValueErrors
 */
export type SetValueErrors = (errors: ValueErrors) => void

/**
 * Custom hook for handling user fields for registration/update.
 *
 * @return UseRegistration
 */
function useRegistration(): UseRegistration {
    // Init all state fields.
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [phone, setPhone] = useState("");
    const [jobTitleLevel, setJobTitleLevel] = useState("");
    const [functionalArea, setFunctionalArea] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [industry, setIndustry] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [department, setDepartment] = useState("");

    // Load all fields from Local Storage.
    useEffect(() => {
        if (!isLoaded) {
            const values = JSON.parse(localStorage.getItem("ssoRegistrationValues") || "{}");
            setEmail(values.email || "");
            setPassword(values.password || "");
            setFirstName(values.firstName || "");
            setLastName(values.lastName || "");
            setCompanyName(values.companyName || "");
            setJobTitle(values.jobTitle || "");
            setPhone(values.phone || "");
            setJobTitleLevel(values.jobTitleLevel || "");
            setFunctionalArea(values.functionalArea || "");
            setCompanySize(values.companySize || "");
            setIndustry(values.industry || "");
            setCountry(values.country || "");
            setState(values.state || "");
            setCity(values.city || "");
            setStreet(values.street || "");
            setPostalCode(values.postalCode || "");
            setDepartment(values.department || "");
            setIsLoaded(true);
        }
    }, [isLoaded]);

    // Submit registration form.
    useEffect(() => {
        if (isSubmitted) {
            const values = {
                email,
                password,
                firstName,
                lastName,
                companyName,
                jobTitle,
                phone,
                jobTitleLevel,
                functionalArea,
                companySize,
                industry,
                country,
                state,
                city,
                street,
                postalCode,
                department,
            };
            localStorage.setItem("ssoRegistrationValues", JSON.stringify({...values, password: ""}));
            setIsSubmitted(false);
        }
    }, [
        isSubmitted,
        email,
        password,
        firstName,
        lastName,
        companyName,
        jobTitle,
        phone,
        jobTitleLevel,
        functionalArea,
        companySize,
        industry,
        country,
        state,
        city,
        street,
        postalCode,
        department,
    ]);

    return {
        values: {
            email,
            password,
            firstName,
            lastName,
            companyName,
            jobTitle,
            phone,
            jobTitleLevel,
            functionalArea,
            companySize,
            industry,
            country,
            state,
            city,
            street,
            postalCode,
            department
        },
        setValues: {
            setEmail,
            setPassword,
            setFirstName,
            setLastName,
            setCompanyName,
            setJobTitle,
            setPhone,
            setJobTitleLevel,
            setFunctionalArea,
            setCompanySize,
            setIndustry,
            setCountry,
            setState,
            setCity,
            setStreet,
            setPostalCode,
            setDepartment,
        },
        save: () => setIsSubmitted(true),
        isLoading: !isLoaded
    };
}

export default useRegistration;
