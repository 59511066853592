import React, {useState} from "react";
import $ from "jquery";
import RegistrationModal from "./RegistrationModal";
import {useUser} from "../providers/UserProvider";

/**
 * Component which handles register/subscribe button in the header.
 *
 * @constructor
 */
function HeaderRegister(): JSX.Element {
    const {isAuthenticated} = useUser();
    const [modal, setModal] = useState(false);

    const link = isAuthenticated ?
        '<a href="https://cyberrisk.dragonforms.com/loading.do?omedasite=SCM_newsletter" target="_blank" rel="noopener noreferrer">Subscribe</a>' :
        '<button id="sso-header-register">Register</button>';
    $('#subscribe-link > a').replaceWith(link);
    if (!isAuthenticated) {
        $('#sso-header-register').on('click', () => {
            setModal(true);
        });
    }

    return isAuthenticated ? <></> : <RegistrationModal show={modal} setShow={setModal} />;
}

export default HeaderRegister;
