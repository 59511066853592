import React, {useReducer} from "react";
import {useUser} from "../providers/UserProvider";
import {Button} from "react-bootstrap";
import RegistrationModal from "./RegistrationModal";
import {PostType, Status, SubmitContentRegistration, submitContentRegistration} from "../../api/api";
import {log} from "../../utility/debug";
import GlobalError from "./GlobalError";
import Loader from "./Loader";
import RegularWrapper from "./RegularWrapper";
import Login from "../forms/buttons/Login";

/**
 * @interface Props
 */
interface Props {
    id: "awards" | "labs"
}

/**
 * @interface State
 */
interface State {
    isModalShown: boolean,
    subscriptionStatus: Status,
}

/**
 * @enum ActionType
 */
enum ActionType {
    ModalToggled = "ModalToggled",
    SubscribeClicked = "SubscribeClicked",
    SubscribeSuccessful = "SubscribeSuccessful",
    SubscribeErrored = "SubscribeErrored",
}

/**
 * @interface Action
 */
interface Action {
    type: ActionType,
    isModalShown?: boolean,
}

/**
 * @type Dispatch
 */
type Dispatch = (action: Action) => void

/**
 * Component for handling subscriptions to content like Awards and Labs.
 *
 * @constructor
 */
function ContentSubscription({id}: Props): JSX.Element {
    const {isAuthenticated, user, accessToken} = useUser();
    const [state, dispatch]: [State, Dispatch] = useReducer(subscriptionReducer, {
        isModalShown: false,
        subscriptionStatus: Status.NotSent,
    });

    const descriptionText = id === "awards" ?
        "Sign up to receive all SC Awards updates." :
        "Sign up to receive all Labs updates.";
    const description = <div className="description d-md-inline-block">{descriptionText}</div>;
    switch (state.subscriptionStatus) {
        case Status.Success:
            return (
                <RegularWrapper variant="dark" isInline={true}>
                    {description}
                    <div className="float-md-right">
                        <Button variant="primary">✓</Button>
                    </div>
                </RegularWrapper>
            );
        case Status.Error:
            return <GlobalError variant="dark" isInline={true} />;
        case Status.Fetching:
            return <Loader />;
        case Status.NotSent:
            if (isAuthenticated) {
                const onSubscribeClick = () => {
                    dispatch({type: ActionType.SubscribeClicked});
                    subscribe(user.authID, accessToken, id, dispatch);
                };
                return (
                    <RegularWrapper variant="dark" isInline={true}>
                        {description}
                        <div className="float-md-right">
                            <Button variant="primary" onClick={onSubscribeClick}>Subscribe</Button>
                        </div>
                    </RegularWrapper>
                );
            } else {
                const setModal = (isModalShown: boolean) => dispatch({type: ActionType.ModalToggled, isModalShown});
                return (
                    <RegularWrapper variant="dark" isInline={true}>
                        {description}
                        <div className="float-md-right">
                            <Login className="btn btn-white mr-3" />
                            <Button variant="primary" onClick={() => setModal(true)}>Register</Button>
                        </div>
                        <RegistrationModal show={state.isModalShown} setShow={setModal} />
                    </RegularWrapper>);
            }
        default:
            throw Error(`Unrecognized Subscription Status - ${state.subscriptionStatus}`);
    }
}

/**
 * Reducer for ContentSubscription state.
 *
 * @param state
 * @param action
 */
function subscriptionReducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.ModalToggled:
            return {...state, isModalShown: action.isModalShown || false};
        case ActionType.SubscribeClicked:
            return {...state, subscriptionStatus: Status.Fetching};
        case ActionType.SubscribeSuccessful:
            return {...state, subscriptionStatus: Status.Success};
        case ActionType.SubscribeErrored:
            return {...state, subscriptionStatus: Status.Error};
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

/**
 * Perform User API call to subscribe user to the content.
 *
 * @param authID
 * @param token
 * @param id
 * @param dispatch
 */
async function subscribe(authID: string, token: string, id: string, dispatch: Dispatch) {
    const [response, error] = await submitContentRegistration(authID, token, PostType.NewsletterSubscription, id);
    if (!error) {
        switch (response.status) {
            case SubmitContentRegistration.Success:
            case SubmitContentRegistration.Duplicate:
                dispatch({type: ActionType.SubscribeSuccessful});
                break;
            case SubmitContentRegistration.MissingFields:
                log("Missing Fields error for subscription content!");
                dispatch({type: ActionType.SubscribeErrored});
                break;
            default:
                log(response);
                dispatch({type: ActionType.SubscribeErrored});
                break;
        }
    } else {
        log(error);
        dispatch({type: ActionType.SubscribeErrored});
    }
}

export default ContentSubscription;
