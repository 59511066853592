import React from "react";
import RegularWrapper from "./RegularWrapper";
import {Alert, Button} from "react-bootstrap";

function TokenExpired(): JSX.Element {
    return (
        <RegularWrapper>
            <Alert variant="warning">Your session has expired. Please, reload the page to continue.</Alert>
            <Button variant="primary" onClick={() => window.location.reload()}>Reload</Button>
        </RegularWrapper>
    );
}

export default TokenExpired;
