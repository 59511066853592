import React, {useEffect, useReducer} from "react";
import Registration from "../forms/Registration";
import {Alert, Button, Container, Row} from "react-bootstrap";
import {useUser} from "../providers/UserProvider";
import {getRegistrationFields, PostType, SubmitContentRegistration, submitContentRegistration} from "../../api/api";
import GlobalError from "./GlobalError";
import Loader from "./Loader";
import RegularWrapper from "./RegularWrapper";
import WelcomeBack from "./WelcomeBack";
import Terms from "../forms/fields/Terms";
import RegistrationTabs, {Tab} from "./RegistrationTabs";
import {genericError, log} from "../../utility/debug";
import TokenExpired from "./TokenExpired";
import Login from "../forms/buttons/Login";

/**
 * @interface Props
 */
interface Props {
    postType: PostType,
    postID: string | number
}

/**
 * @interface State
 */
interface State {
    isLoadingFields: boolean;
    fields: string[];
    contentRegistrationStatus: "NotSubmitted" | "Success" | "Duplicate" | "InProgress";
    redirectLink: string;
    tab: Tab;
    error?: Error;
}

/**
 * @type Dispatch
 */
type Dispatch = (action: any) => void

/**
 * Component for Whitepaper Download/Event Registration block.
 *
 * @param postType
 * @param postID
 * @constructor
 */
function ContentRegistration({postType, postID}: Props): JSX.Element {
    const {user, isAuthenticated, accessToken, hasAccessTokenExpired} = useUser();
    const [state, dispatch]: [State, Dispatch] = useReducer(registrationReducer, {
        isLoadingFields: true,
        fields: [],
        contentRegistrationStatus: "NotSubmitted",
        redirectLink: "",
        tab: "registration"
    })

    useEffect(() => {
        if (state.isLoadingFields) {
            loadRegistrationFields({authID: user.authID, token: accessToken, postType, postID, dispatch});
        }
    }, [accessToken, postID, postType, state.isLoadingFields, user.authID]);

    useEffect(() => {
        if (isAuthenticated && !state.isLoadingFields && !state.fields.length && state.contentRegistrationStatus === "NotSubmitted") {
            const key = `ssoLoginAction-${postType}-${postID}`;
            if (localStorage.getItem(key) === "true") {
                localStorage.removeItem(key);
                dispatch({type: "StartRegistration"});
            }
        }
    }, [isAuthenticated, postID, postType, state.contentRegistrationStatus, state.fields.length, state.isLoadingFields]);

    useEffect(() => {
        if (isAuthenticated && state.contentRegistrationStatus === "InProgress") {
            onSubmitContentRegistration({authID: user.authID, token: accessToken, postType, postID, dispatch});
        }
    }, [accessToken, isAuthenticated, postID, postType, state.contentRegistrationStatus, user.authID]);

    useEffect(() => {
        if (state.contentRegistrationStatus === "Success" && state.redirectLink) {
            window.location.replace(state.redirectLink);
        }
    }, [state.contentRegistrationStatus, state.redirectLink]);

    if (state.isLoadingFields) {
        return <Loader expectedWaitTimeInSeconds={1} />;
    }
    if (state.contentRegistrationStatus === "InProgress") {
        return <Loader expectedWaitTimeInSeconds={5} />;
    }

    if (state.error) {
        return <GlobalError error={state.error} />;
    }

    if (hasAccessTokenExpired) {
        return <TokenExpired />;
    }

    switch (state.contentRegistrationStatus) {
        case "Success":
            const successMessage = postType === PostType.Whitepaper ?
                "The whitepaper should start downloading soon." :
                "Successfully registered for the event! You should receive the registration email soon.";
            return (
                <RegularWrapper>
                    <Alert variant="success">{successMessage}</Alert>
                </RegularWrapper>
            );
        case "Duplicate":
            return (
                <RegularWrapper>
                    <Alert variant="info">You are already registered for the event!</Alert>
                </RegularWrapper>
            );
    }

    const actionText = (postType === PostType.Webcast) ? "Register for Event" : "Download";
    const buttonText = isAuthenticated ? actionText : "Create Account";
    const switchTab = (tab: Tab) => dispatch({type: "SwitchTab", tab});
    const additionalAction = () => {
        localStorage.setItem(`ssoLoginAction-${postType}-${postID}`, "true");
    };
    const loginButton = <Login
        className="btn btn-primary px-3 w-100"
        title={`Log in and ${actionText}`}
        additionalAction={additionalAction}
    />
    return (
        <RegularWrapper>
            <Container className="mt-4 mt-sm-0">
                {isAuthenticated ? <WelcomeBack /> : <RegistrationTabs tab={state.tab} switchTab={switchTab} />}
                <Row className="form mt-4">
                    {state.tab === "registration" ? (
                        <Registration
                            fields={state.fields}
                            userUpdated={() => dispatch({type: "UserUpdated"})}
                            loginButton={loginButton}
                        >
                            {isAuthenticated && postType === PostType.Webcast ? <></> : <Terms buttonName={buttonText} />}
                            <Button variant="primary" type="submit">{buttonText}</Button>
                        </Registration>
                    ) : loginButton}
                </Row>
            </Container>
        </RegularWrapper>
    );
}

/**
 * Reducer for ContentRegistration state.
 *
 * @param state
 * @param action
 */
function registrationReducer(state: State, action: any): State {
    switch (action.type) {
        case "SwitchTab":
            return {...state, tab: action.tab};
        case "Loaded":
            return {...state, isLoadingFields: false, fields: action.fields};
        case "Error":
            return {...state, error: action.error, isLoadingFields: false, contentRegistrationStatus: "NotSubmitted"};
        case "StartRegistration":
        case "UserUpdated":
            return {...state, fields: [], contentRegistrationStatus: "InProgress"};
        case "MissingFields":
            return {...state, fields: action.fields, contentRegistrationStatus: "NotSubmitted"};
        case "SubmitSuccess":
            return {...state, contentRegistrationStatus: "Success", redirectLink: action.redirectLink};
        case "SubmitDuplicate":
            return {...state, contentRegistrationStatus: "Duplicate"}
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

/**
 * @interface LoadRegistrationFieldsParams
 */
interface LoadRegistrationFieldsParams {
    authID: string;
    token: string;
    postType: PostType;
    postID: string | number;
    dispatch: Dispatch;
}

/**
 * Loads missing required fields for the user.
 *
 * @param authID
 * @param token
 * @param postType
 * @param postID
 * @param dispatch
 */
async function loadRegistrationFields({authID, token, postType, postID, dispatch}: LoadRegistrationFieldsParams) {
    const [fields, error] = await getRegistrationFields(authID, token, postType, postID);
    if (!error) {
        dispatch({type: "Loaded", fields});
    } else {
        dispatch({type: "Error", error});
    }
}

/**
 * @interface OnSubmitContentRegistrationParams
 */
interface OnSubmitContentRegistrationParams {
    authID: string;
    token: string;
    postType: PostType;
    postID: string | number;
    dispatch: Dispatch;
}

/**
 * Performs event registration/whitepaper download.
 *
 * @param authID
 * @param token
 * @param postType
 * @param postID
 * @param dispatch
 */
async function onSubmitContentRegistration({
                                               authID,
                                               token,
                                               postType,
                                               postID,
                                               dispatch
                                           }: OnSubmitContentRegistrationParams) {
    const [response, error] = await submitContentRegistration(authID, token, postType, postID);
    if (!error) {
        switch (response.status) {
            case SubmitContentRegistration.Success:
                dispatch({type: "SubmitSuccess", redirectLink: response.redirectLink || response.downloadLink || ""});
                break;
            case SubmitContentRegistration.Duplicate:
                dispatch({type: "SubmitDuplicate"});
                break;
            case SubmitContentRegistration.MissingFields:
                dispatch({type: "MissingFields", fields: response.data.missingFields});
                break;
            default:
                log(response);
                dispatch({type: "Error", error: genericError()});
        }
    } else {
        log(error);
        dispatch({type: "Error", error: genericError()});
    }
}

export default ContentRegistration;
