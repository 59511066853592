import React from 'react';

/**
 * @interface Props
 */
interface Props {
    buttonName: string
}

/**
 * Terms component.
 *
 * @param buttonName
 * @constructor
 */
function Terms({buttonName}: Props): JSX.Element {
    return (
        <div className="form-group terms-policy">
            <p>By clicking the {buttonName} button below, you agree to SC Media <a href="/termsandconditions">Terms
                and Conditions</a> and <a href="/privacypolicy">Privacy Policy</a>.
            </p>
        </div>
    )
}

export default Terms;
