import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {Auth0Provider} from "@auth0/auth0-react";
import reportWebVitals from './reportWebVitals';
import {CookiesProvider} from "react-cookie";
import {UserProvider} from "./components/providers/UserProvider";

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.SSO_AUTH0_DOMAIN || ''}
            clientId={process.env.SSO_AUTH0_CLIENT_ID || ''}
            redirectUri={process.env.SSO_AUTH0_REDIRECT_URI || ''}
        >
            <UserProvider>
                <CookiesProvider>
                    <App/>
                </CookiesProvider>
            </UserProvider>
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('cra-sso')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
