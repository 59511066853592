import React from "react";
import SelectInput, {stringToOption} from "./SelectInput";

/**
 * @interface Props
 */
interface Props {
    field: string;
    value: string;
    setValue: (value: string) => void;
    errors?: string[];
}

/**
 * Functional Area field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function FunctionalArea({field, value, setValue, errors}: Props): JSX.Element {
    const options = [
        'IT/IS/Security',
        'Governance/Risk/Compliance/Privacy/Audit',
        'Vendor/Consultant/Re-seller',
        'Sales/Marketing',
        'Executive Management',
        'Educator/Student',
        'Other (Finance, Operations, HR, etc)',
    ];
    return <SelectInput name={field} label="Functional Area" options={options.map(stringToOption)} value={value} setValue={setValue} errors={errors} />;
}

export default FunctionalArea;
