import {APP_NAME} from "../components/App";

/**
 * Log function.
 *
 * It provides logging for debugging purposes currently.
 * But in the future might be extended to sending error logs to a server in order to monitor issues on production as well.
 *
 * @param output Error|object|string
 */
export const log = (output: any): void => {
    if ((process.env.SSO_DEBUG || "") === "true" || window.location.search.indexOf('ssoDebugMode=1') !== -1) {
        console.log(APP_NAME, output);
    }
}

export const GENERIC_ERROR_MESSAGE = "An error has occurred.";

export const genericError = (): Error => new Error(GENERIC_ERROR_MESSAGE);
