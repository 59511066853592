import React from "react";

/**
 * @interface Props
 */
interface Props {
    children: any,
    variant?: "primary" | "dark",
    isInline?: boolean
}

/**
 * Wrapper component.
 *
 * It should be used by outer components so appropriate styles are applied.
 *
 * @param children
 * @param variant
 * @param isInline
 * @constructor
 */
function RegularWrapper({children, variant, isInline}: Props): JSX.Element {
    const inline = isInline ? " inline clearfix" : "block";
    return <div className={`sso-registration ${variant || "primary"} ${inline}`}>{children}</div>;
}

export default RegularWrapper;
