import axios, {AxiosResponse} from "axios";
import {ValueErrors, Values} from "../components/hooks/useRegistration";
import {GENERIC_ERROR_MESSAGE, genericError, log} from "../utility/debug";
const _ = require('lodash');

/**
 * @enum Status
 */
export enum Status {
    NotSent = "NotSent",
    Fetching = "Fetching",
    Error = "Error",
    Success = "Success"
}

/**
 * @type SetStatus
 */
export type SetStatus = (status: Status) => void

/**
 * @enum PostType
 */
export enum PostType {
    Webcast = "hm-webcast",
    Whitepaper = "cra_whitepaper",
    NewsletterSubscription = "newsletter-subscription",
}

/**
 * @interface Violation
 */
interface Violation {
    code: null | string,
    message: string,
    propertyPath: keyof Values
}

/**
 * @type ResponseWithErrors
 */
type ResponseWithErrors = [any | null, ValueErrors | null];

/**
 * @type ResponseWithError
 */
type ResponseWithError = [any | null, Error | null];

const API_PREFIX = "/api/v1";

/**
 * Validate which HTTP statuses represent an unmanagable error.
 *
 * @param status number
 *
 * @return boolean Returns true on a valid status code.
 */
const validateStatus = (status: number): boolean => status >= 200 && status < 500;

/**
 * Performs a POST API call.
 *
 * @param token string Auth token if a user is logged in.
 *
 * @return Function
 */
function post(token: string = ""): Function {
    const headers: any = {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return axios.create({
        method: "post",
        baseURL: process.env.SSO_USER_API_HOST + API_PREFIX,
        headers: headers,
        validateStatus: validateStatus
    }).post;
}

/**
 * Performs a PATCH API call.
 *
 * @param token string Auth token if a user is logged in.
 *
 * @return Function
 */
function patch(token: string = ""): Function {
    const headers: any = {
        "Accept": "application/json",
        "Content-Type": "application/merge-patch+json"
    }
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return axios.create({
        method: "patch",
        baseURL: process.env.SSO_USER_API_HOST + API_PREFIX,
        headers: headers,
        validateStatus: validateStatus
    }).patch;
}

/**
 * Performs a GET API call.
 *
 * @param token string Auth token if a user is logged in.
 *
 * @return Function
 */
function get(token: string = ""): Function {
    const headers: any = {
        "Accept": "application/json"
    }
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return axios.create({
        method: "get",
        baseURL: process.env.SSO_USER_API_HOST + API_PREFIX,
        headers: headers,
        validateStatus: validateStatus
    }).get;
}

/**
 * Calls user creation API method.
 *
 * @param values Values
 *
 * @return Promise<ResponseWithErrors>
 */
export async function createUser(values: Values): Promise<ResponseWithErrors> {
    try {
        const _values: any = {createdAt: (new Date()).toISOString()};
        _.forEach(values, (value: string, key: string) => {
            if (value) {
                _values[key] = value;
            }
        });
        const response = await post()("/users", JSON.stringify(_values));
        return handleUserResponse(response);
    } catch (error) {
        log(error);
        return [null, {alert: GENERIC_ERROR_MESSAGE}];
    }
}

/**
 * Calls user data update API method.
 *
 * @param authID string User ID
 * @param token Auth access token
 * @param values any Values for user fields. Only provided here will be updated.
 *
 * @return Promise<ResponseWithErrors>
 */
export async function updateUser(authID: string, token: string, values: any): Promise<ResponseWithErrors> {
    try {
        const _values: any = {createdAt: (new Date()).toISOString()};
        _.forEach(values, (value: string, key: string) => {
            if (value) {
                _values[key] = value;
            }
        });
        const response = await patch(token)(`/users/${authID}`, JSON.stringify(_values));
        return handleUserResponse(response);
    } catch (error) {
        log(error);
        return [null, {alert: GENERIC_ERROR_MESSAGE}];
    }
}

/**
 * Handles response from user creation and update API methods.
 *
 * @param response AxiosResponse<any> HTTP API response.
 *
 * @return ResponseWithErrors
 */
function handleUserResponse(response: AxiosResponse<any>): ResponseWithErrors {
    if (response.status >= 200 && response.status < 300) {
        return [response.data, null];
    } else {
        const violations = response.data.violations || [];
        if (violations.length) {
            const errors: ValueErrors = {};
            for (let index = 0; index < violations.length; index++) {
                const violation: Violation = violations[index];
                if (!errors[violation.propertyPath]) {
                    errors[violation.propertyPath] = [];
                }
                // @ts-ignore
                errors[violation.propertyPath].push(violation.message);
            }
            return [response.data, errors];
        } else {
            return [response.data, {alert: response.data.title || GENERIC_ERROR_MESSAGE}];
        }
    }
}

/**
 * Calls user data retrieval API method.
 *
 * @param authID string User ID
 * @param token string Auth access token
 */
export async function getUser(authID: string, token: string): Promise<ResponseWithError> {
    try {
        const response = await get(token)(`/users/${authID}`);
        switch (response.status) {
            case 200:
                if (response.data.authID !== authID) {
                    return [null, new Error("User ID mismatch!")];
                }
                return [response.data, null];
            case 404:
                return [null, new Error("User not found!")];
            default:
                log(response);
                return [null, new Error("Error retrieving user data.")];
        }
    } catch (error) {
        log(error);
        return [null, genericError()];
    }
}

/**
 * Calls registration fields retrieval API method.
 *
 * @param authID string User ID
 * @param token string Auth access token
 * @param postType PostType
 * @param postID string|number
 *
 * @return Promise<ResponseWithError>
 */
export async function getRegistrationFields(authID: string, token: string, postType: PostType, postID: string | number): Promise<ResponseWithError> {
    try {
        const id = authID || "0";
        const response = await get(token)(`/users/${id}/content/fields`, {
            params: {type: postType, id: postID}
        });
        switch (response.status) {
            case 200:
            case 404:
                return [response.data.missingFields, null];
            case 403:
                return [response.data.missingFields, new Error(response.data.exception || `${GENERIC_ERROR_MESSAGE} Please, log out and log in again.`)];
            default:
                log(response);
                return [null, new Error(response.data.exception || GENERIC_ERROR_MESSAGE)];
        }
    } catch (error) {
        log(error);
        return [null, genericError()];
    }
}

/**
 * @enum SubmitContentRegistration
 */
export enum SubmitContentRegistration {
    Success = "Success",
    Duplicate = "Duplicate",
    MissingFields = "MissingFields",
}

/**
 * Calls whitepaper download/event registration API method.
 *
 * @param authID string User ID
 * @param token string Auth access token
 * @param postType PostType
 * @param postID string|number
 *
 * @return Promise<ResponseWithError>
 */
export async function submitContentRegistration(authID: string, token: string, postType: PostType, postID: string | number): Promise<ResponseWithError> {
    try {
        const _id = encodeURIComponent(authID);
        const response = await post(token)(`/users/${_id}/content/submit`, "", {
            params: {type: postType, id: postID}
        });
        switch (response.status) {
            case 200:
                return [{...response.data, status: SubmitContentRegistration.Success}, null];
            case 409:
                return [{...response.data, status: SubmitContentRegistration.Duplicate}, null];
            case 422:
                return [{...response.data, status: SubmitContentRegistration.MissingFields}, null];
            default:
                return [null, genericError()];
        }
    } catch (error) {
        log(error);
        return [null, genericError()];
    }
}
