import React from "react";
import SelectInput, {stringToOption} from "./SelectInput";

/**
 * @interface Props
 */
interface Props {
    field: string;
    value: string;
    setValue: (value: string) => void;
    errors?: string[];
}

/**
 * Industry field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function Industry({field, value, setValue, errors}: Props): JSX.Element {
    const options = [
        'Education',
        'Energy, Telecom, Utilities',
        'Financial Services',
        'Government & Public Sector',
        'Healthcare, Medical, Pharma',
        'Manufacturing & Goods',
        'Misc Services',
        'Retail & Wholesale',
        'Technology',
        'Travel, Entertainment, Transportation',
        'Other',
    ];
    return <SelectInput name={field} label="Industry" options={options.map(stringToOption)} value={value} setValue={setValue} errors={errors} />;
}

export default Industry;
