import React from "react";
import RegularWrapper from "./RegularWrapper";
import {GENERIC_ERROR_MESSAGE} from "../../utility/debug";

/**
 * @interface Props
 */
interface Props {
    error?: Error;
    variant?: "primary" | "dark";
    isInline?: boolean
}

/**
 * Component which displays an error message in case of a global error.
 *
 * @param error
 * @param variant
 * @param isInline
 * @constructor
 */
function GlobalError({error, variant, isInline}: Props): JSX.Element {
    const errorMsg = error ? error.message : GENERIC_ERROR_MESSAGE;
    return (
        <RegularWrapper variant={variant} isInline={isInline}>
            {errorMsg} Please,
            contact <a href="mailto:support@cyberriskalliance.com">support@cyberriskalliance.com</a>.
        </RegularWrapper>
    );
}

export default GlobalError;
