import React from 'react';
import {useCookies} from "react-cookie";
import {useUser} from "../../providers/UserProvider";

/**
 * @interface Props
 */
interface Props {
    children: string
    className?: string
}

/**
 * Logout button component.
 *
 * @param children
 * @param className
 * @constructor
 */
function Logout({children, className}: Props): JSX.Element {
    const {logout} = useUser();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies(['ssoRedirectTo']);
    const onClick = () => {
        setCookie('ssoRedirectTo', window.location.href, {path: '/'});
        logout();
    }

    return (
        <button className={className || 'btn'} onClick={onClick}>{children}</button>
    );
}

export default Logout;
