import React from "react";
import {Row} from "react-bootstrap";

export type Tab = "login" | "registration";

interface Props {
    tab: Tab;
    switchTab: (tab: Tab) => void;
}

interface Button {
    key: Tab;
    title: string;
}

/**
 * Component for displaying registration tabs (login/registration).
 *
 * @constructor
 */
function RegistrationTabs({tab, switchTab}: Props) {
    const buttons: Button[] = [
        {key: "login", title: "Log in"},
        {key: "registration", title: "Registration"},
    ];
    return (
        <Row className="tabs">
            {
                buttons.map(button => {
                    return (
                        <button
                            key={button.key}
                            className={`tab tab-${button.key} ${tab === button.key ? "active" : ""}`}
                            onClick={() => switchTab(button.key)}
                        >{button.title}
                        </button>
                    );
                })
            }
        </Row>
    );
}

export default RegistrationTabs;
