import React from "react";
import {Button, Modal} from "react-bootstrap";
import Registration from "../forms/Registration";
import Terms from "../forms/fields/Terms";

/**
 * @interface Props
 */
interface Props {
    show: boolean,
    setShow: (show: boolean) => void
}

/**
 * Registration Modal component.
 *
 * @param show
 * @param setShow
 * @constructor
 */
function RegistrationModal({show, setShow}: Props): JSX.Element {
    const handleClose = () => setShow(false);
    const buttonText = "Create Account";
    return (
        <Modal
            show={show}
            onHide={handleClose}
        >
            <Modal.Body className="sso-registration">
                <Registration fields={['email', 'password', 'firstName', 'lastName', 'companyName', 'jobTitle']}
                              userUpdated={() => {}}>
                    <Terms buttonName={buttonText}/>
                    <Button variant="primary" type="submit">{buttonText}</Button>
                </Registration>
            </Modal.Body>
        </Modal>
    );
}

export default RegistrationModal;
