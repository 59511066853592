import React from "react";
import TextInput from "./TextInput";

/**
 * @interface Props
 */
interface Props {
    field: string,
    value: string,
    setValue: (value: string) => void,
    errors?: string[]
}

/**
 * Password field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function Password({field, value, setValue, errors}: Props): JSX.Element {
    return <TextInput name={field} type="password" label="Password" value={value} setValue={setValue} errors={errors} />;
}

export default Password;
