import React from "react";
import SelectInput, {stringToOption} from "./SelectInput";

/**
 * @interface Props
 */
interface Props {
    field: string;
    value: string;
    setValue: (value: string) => void;
    errors?: string[];
}

/**
 * Job Title Level field component.
 *
 * @param field
 * @param value
 * @param setValue
 * @param errors
 * @constructor
 */
function JobTitleLevel({field, value, setValue, errors}: Props): JSX.Element {
    const options = [
        'C-level and equivalent',
        'VP/SVP/EVP and equivalent',
        'Director and equivalent',
        'Manager and equivalent',
        'Non-management/Staff',
        'Consultant',
        'Other',
    ];
    return <SelectInput name={field} label="Job Title Level" options={options.map(stringToOption)} value={value} setValue={setValue} errors={errors} />;
}

export default JobTitleLevel;
