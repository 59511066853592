import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {useCookies} from "react-cookie";

/**
 * @interface Props
 */
interface Props {
    className?: string;
    title?: string;
    additionalAction?: () => void;
}

/**
 * Login button component.
 *
 * @param className
 * @param title
 * @param additionalAction
 * @constructor
 */
function Login({className, title, additionalAction}: Props): JSX.Element {
    const {
        loginWithRedirect,
    } = useAuth0();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie] = useCookies(['ssoRedirectTo']);
    const onClick = () => {
        setCookie('ssoRedirectTo', window.location.href, {path: '/'});
        if (additionalAction) {
            additionalAction();
        }
        loginWithRedirect();
    }

    return (
        <button onClick={onClick} className={className || 'btn'}>{title || 'Log in'}</button>
    );
}

export default Login;
